// CfgTablePopup

<template>
    <div>
    <v-dialog v-model="dialog" max-width="1200px" height="750px" persistent scrollable>
		<v-card>
			<v-toolbar flat color="indigo lighten-1">
				<!--<v-toolbar-title dense class="font-weight-light">Things</v-toolbar-title>-->
				<v-row>
					<v-col cols="12" sm="6">
						<v-text-field hide-details rounded filled color="white" autocomplete="new-password"
							v-model="item.table_name" label="table_name" @keydown="hasChanged()"
                            :disabled="!!item.id"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="6">
						<v-text-field hide-details rounded filled color="white" autocomplete="new-password"
							v-model="item.table_title" label="Title" @keydown="hasChanged()"
						></v-text-field>
					</v-col>
				</v-row>
			</v-toolbar>
			<v-card-text>
				<v-row>
                    <v-card flat tile style="width: 100%; max-height: 600px;" class="overflow-y-auto">
                    <v-container fluid>
                        <v-row dense v-for="model in item.cfgcolumns" :key="model.id"
                            @click="activate(model)" @blur="deactivate(model)" align="center" justify="center">
                            <template v-for="field in fields">
                                <v-col cols="12" :sm="field.len" :key="field.key" class="text-truncate">
                                    <v-text-field v-if="field.type=='tf'"
                                        hide-details dense :flat="!inEdit(model)"
                                        :disabled="!!model.locked && field.lock"
                                        :solo="!inEdit(model)"          
                                        :shaped="inEdit(model)"
                                        :readonly="!inEdit(model)"
                                        v-model="model[field.key]" :filled="inEdit(model)" :clearable="inEdit(model)"
                                        :error="!(model[field.key] || !field.req)"
                                        color="white"
                                        :ref="field.ref"
                                        :label="field.name" :placeholder="'...'"
                                        @keydown="hasChanged(model)"
                                        @keyup.enter="tabToNext(field)"
                                        @click:clear="hasChanged(model)"
                                    ></v-text-field>
                                    <v-switch v-else-if="field.type=='bf'"
                                        hide-details dense class="mt-1" v-model="model[field.key]"
                                        :disabled="!!model.locked && field.lock"
                                        :label="field.name" :filled="inEdit(model)" :shaped="inEdit(model)"
                                        color="green" @change="hasChanged(model)"
                                    ></v-switch>
                                    <v-select v-else-if="field.type=='sf'" hide-details dense
                                        :disabled="!!model.locked && field.lock"
                                        :shaped="inEdit(model)"
                                        v-model="model[field.key]" :flat="!inEdit(model)"
                                        :ref="field.ref" :clearable="inEdit(model)"
                                        :readonly="!inEdit(model)" :filled="inEdit(model)"
                                        @change="hasChanged(model)"
                                        @keyup.enter="tabToNext(field)"
                                        @click:clear="hasChanged(model)"
                                        :items="states"
                                        item-text="name" :placeholder="'...'"
                                        :label="field.name" :solo="!inEdit(model)"
                                    ></v-select>
                                </v-col>
                            </template>
                            <!--<v-col>
                                <v-btn v-if="isDirty(model)" color="green darken-3" fab small @click.stop="onSave(model)" >
                                    <v-icon>mdi-check-bold</v-icon>
                                </v-btn>
                            </v-col>-->
                        </v-row>
                    </v-container>
                    </v-card>
    			</v-row>
			</v-card-text>
			<v-card-actions>
				<v-btn text color="orange" @click="onCancel">Abbrechen</v-btn>
				<v-spacer/>
				<v-btn :disabled="anyDirty()" text color="primary" @click="onNewColumn">New Column</v-btn>
				<v-spacer/>
                <v-btn v-if="anyDirty()" color="success" @click="onSaveAll">Save</v-btn>
                <v-btn v-else color="secondary" @click="onClose">Close</v-btn>
			</v-card-actions>
		</v-card>
        <v-overlay absolute :value="loading">
        	<v-progress-circular indeterminate size="64" width="7" color="cyan darken-4"></v-progress-circular>
        </v-overlay>
    </v-dialog>          
    <CfgColumnPopup :model="popup" @popup-cancelled="onPopupCancelled()" @popup-submitted="onPopupSubmitted()" />
    </div>
</template>
<script>
import CfgColumnPopup from './CfgColumnPopup'
import { mapGetters } from 'vuex'
export default {
	name: 'CfgTablePopup',
	components: {
        CfgColumnPopup
    },
    props: {
        model: {
            type: Object,
            default () {
                return {}
            }
        },
    },
	data() {
		return {
			loading: false,
            dialog: false,
            popup: { dialog: false },
            model_id: null,
            activeRow: null,
            dirtyRows: [],
            isDirtyParent: false,
            fields: [
                {type: 'tf', name: 'column_name', key: 'column_name', value: '', ref: 'tf1', next: 'tf3', len: 3, req: true, lock: true,},
                //{type: 'tf', name: 'Title', key: 'column_title', value: '', ref: 'tf2', next: 'tf3', len: 3, req: false},
                {type: 'tf', name: 'Type', key: 'ctype', value: '', ref: 'tf3', next: 'tf4', len: 3, req: false, lock: true,},
                //{type: 'tf', name: 'Size', key: 'size', value: 'Florida', ref: 'sf1', next: 'sf1', len: 2, req: false},
                //{type: 'tf', name: 'Scale', key: 'scale', value: 'Florida', ref: 'sf1', next: 'sf1', len: 1, req: false},
                {type: 'tf', name: 'RefTable', key: 'reftable', value: '', ref: 'tf4', next: 'tf5', len: 2, req: false, lock: true,},
                {type: 'bf', name: 'Req', key: 'req', value: false, ref: 'bf1', next: 'bf1', len: 2, req: false, lock: false,},
                {type: 'tf', name: 'Default', key: 'defval', value: '', ref: 'tf5', next: 'tf1', len: 2, req: false, lock: false,},
            ],
            column: {},
		}
	},
	computed: {
		...mapGetters({
			item: 'cfgtables/getCfgTable',
		}),
    },
	mounted() {},
	methods: {
        fetchData() {
            this.loading = true
            let self = this
            this.$store.dispatch('cfgtables/editCfgTable', this.model_id)
            .then((response) => {
                console.log('item:', this.item)
                self.loading = false
            })
            .catch((error) => {
                console.log("fetchData->error")
                console.log(error)
                this.msgError(error.response.data)
                self.loading = false
            });
        },
        closeDialog(event) {
            this.loading = false
            this.dialog = false
            this.$store.commit('cfgtables/resetCfgTable')
            this.$emit(event)
        },
        onNewColumn() {
            console.log(this.item)
            this.popup.parent_id = this.item.id
            this.popup.parent_name = this.item.table_name
			this.popup.dialog = true
        },
        onSubmit() {
            this.loading = true	
            const data = this.item
            this.$store.dispatch('cfgtables/storeCfgTable', data)
            .then((response) => {
                this.closeDialog('popup-submitted')
            })
            .catch((error) => {
                console.log('submit->error')
                console.log(error)
            });
        },
        onCancel() {
            this.closeDialog('popup-cancelled')
        },
        onClose() {
            this.closeDialog('popup-submitted')
        },
        onSave(model){
            this.activeRow = null
            this.dirtyRows.splice(this.dirtyRows.indexOf(model.id), 1)
        },
        activate(model){
            this.activeRow = model.id
        },
        deactivate(model){
            this.activeRow = null
        },
        isActive(model){
            return this.activeRow == model.id
        },
        inEdit(model){
            return this.isActive(model) || this.isDirty(model)
        },
        isDirty(model){
            return this.dirtyRows.includes(model.id)
        },
        anyDirty(){
            return this.dirtyRows.length > 0 || this.isDirtyParent     
        },
        onSaveAll () {
            let columnsToSave = []
            if(this.item.cfgcolumns) {
                columnsToSave = this.item.cfgcolumns.filter(e => this.dirtyRows.includes(e.id))
                columnsToSave.forEach(e => e.changed = 1);
            }
            console.log(columnsToSave)
            this.loading = true
            const data = this.item
            data.cfgcolumns = columnsToSave
            this.$store.dispatch('cfgtables/storeCfgTable', data)
            .then((response) => {
                //this.closeDialog('popup-submitted')
                this.model_id = this.item.id
                this.activeRow = null
                this.dirtyRows = []
                this.isDirtyParent = false
                this.loading = false
            })
            .catch((error) => {
                console.log('cfgtable->submit->error')
                console.log(error)
                this.msgError(error.response.data)
                this.loading = false
            });
        },
        hasChanged(model) {
            if(model) {
                this.dirtyRows.indexOf(model.id) == -1 ? this.dirtyRows.push(model.id) : ()=>{}
            } else {
                this.isDirtyParent = true
            }
        },
        onPopupCancelled() {this.popup.dialog = false},
        onPopupSubmitted() {
            this.popup.dialog = false
            this.fetchData()
        },
	},
	watch: {
        model: {
            handler (val) {
                if (!val) return;
                if(val.open) {
                    console.log('open dialog')
                    if(Object.keys(val.item).length !== 0) {
                        this.model_id = val.item.id
                        this.fetchData()
                    } else {
                        this.$store.commit('cfgtables/resetCfgTable')
                        this.isDirtyParent = true
                    }
                    this.dialog = true
                }
            },
            deep: true,
        },
    },
};
</script>
<style>
</style>